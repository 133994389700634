import { forwardRef } from 'react';
import Slide from '../global/Slide';
import Expandable from '../global/Expandable'
import Rive, { Layout, Fit } from 'rive-react';

import styles from './ProductFeatures.module.css';

const content = [
  {
    title: 'Core editor features',
    file: '/paint.riv',
    animation: null,
    caption: 'Text, raster assets, fluid layouts, audio, and native desktop support are some of the core features we plan to build over the coming months.',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: 'Community and Marketplace',
    file: '/tom_morello.riv',
    animation: null,
    caption: 'Our community will evolve into a marketplace, allowing designers and developer to purchase and sell Rive files. Marketplace items can immediately be dropped into our editor for free and must be purchased to export.',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: 'Game engine runtimes',
    file: '/switch.riv',
    animation: null,
    caption: 'Extending our runtime support to popular game engines such as Unity, Unreal, and Defold extends our market from product to games across all platforms',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: 'Import plugins',
    file: '/bot.riv',
    animation: 'Idle',
    caption: 'Plugins for existing tools such as After Effects, Illustrator, Figma, and Sketch will make integrating Rive into wider workflow easier than ever, and encourage adoption',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  }
]

const ProductFeatures = forwardRef((props, ref) => (
  <Slide ref={ref} className={styles.roadmap}>
    <h2>Continued product development</h2>
    <h4>As we go-to-market for our initial segment, we need to keep building the full product to capture the total market.</h4>
    <div className={styles.features}>
      {content.map((item) => (
        <Expandable 
          className={styles.calloutVert} 
          key={item.title}
          modalHeader={item.title}
          modalCopy={item.captionLong}
        >
          <div className={styles.ratio}>
            <Rive
              className={styles.featureAnimation}
              src={item.file}
              animation={item.animation}
              layout={new Layout({
                fit: Fit.Cover,
              })}
            />
          </div>
          <h4>{item.title}</h4>
          <p>{item.caption}</p>
        </Expandable>
      ))}
    </div>
  </Slide>
))

export default ProductFeatures;