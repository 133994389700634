import { useRef, useEffect } from 'react';
import './App.css';

import Cover from './slides/1_Cover';
import Problem from './slides/2_Problem';
import Solution from './slides/3_Solution';
import Runtimes from './slides/4_Runtimes';
import StateMachine from './slides/5_StateMachine';
import Market from './slides/6_Market';
import ProductPlacement from './slides/ProductPlacement';
import Applications from './slides/7_Applications';
import ProductFeatures from './slides/8_ProductFeatures';
import Analytics from './slides/9_Analytics';
import Strategy from './slides/10_Strategy';
import Team from './slides/11_Team';
import Ask from './slides/12_Ask';
import Close from './slides/13_Close';

function App() {
  const cover = useRef(null);
  const problem = useRef(null);
  const solution = useRef(null);
  const runtimes = useRef(null);
  const stateMachine = useRef(null);
  const market = useRef(null);
  // const productPlacement = useRef(null);
  const applications = useRef(null);
  const trajectory = useRef(null);
  const productFeatures = useRef(null);
  const strategy = useRef(null);
  const team = useRef(null);
  const ask = useRef(null);
  const close = useRef(null);
  const slideNumber = useRef(null);
  const blueprint = [
    cover,
    problem,
    solution,
    runtimes,
    stateMachine,
    market,
    // productPlacement,
    applications,
    productFeatures,
    strategy,
    trajectory,
    team,
    ask,
    close,
  ];

  useEffect(() => {
    const keyDown = (event) => {
      if(['ArrowDown', 'ArrowRight'].includes(event.key)) {
        event.preventDefault();
        scrollNext()
      } else if (['ArrowUp', 'ArrowLeft'].includes(event.key)) {
        event.preventDefault();
        scrollPrev()
      }
    }

    let lastKnownScrollPosition = 0;
    let ticking = false;
    const scroll = (event) => {
      lastKnownScrollPosition = window.scrollY;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          checkSlideNo(lastKnownScrollPosition);
          ticking = false;
        });
        ticking = true;
      }
    }

    const checkSlideNo = (_) => {
      const slide = blueprint.reduce((prev, curr) => {
        const py = Math.abs(prev.current.getBoundingClientRect().y)
        const cy = Math.abs(curr.current.getBoundingClientRect().y)
        return py < cy ? prev : curr
      })
      const slideIndex = blueprint.indexOf(slide);
      if (slideIndex !== slideNumber.current) {
        slideNumber.current = slideIndex;
      }
    }
    
    slideNumber.current = 0;
    document.addEventListener("keydown", keyDown, false);
    document.addEventListener("scroll", scroll, false);
    return () => {
      document.removeEventListener("keydown", keyDown, false);
      document.removeEventListener("scroll", scroll, false);
    };
  }, []);

  const scrollNext = () => {
    slideNumber.current = Math.min(slideNumber.current + 1, blueprint.length - 1);
    scrollToSlide(slideNumber.current)
  }

  const scrollPrev = () => {
    slideNumber.current = Math.max(slideNumber.current - 1, 0);
    scrollToSlide(slideNumber.current)
  }

  const scrollToSlide = (slideNo) => {
    if (slideNo >= 0 && slideNo < blueprint.length) {
      blueprint[slideNo].current?.scrollIntoView({behavior: 'smooth'})
    }
  }

  return (
    <>
      <Cover ref={cover} onClick={(n) => scrollToSlide(n)} />
      <Problem ref={problem} />
      <Solution ref={solution} />
      <Runtimes ref={runtimes} />
      <StateMachine ref={stateMachine} />
      <Market ref={market} />
      {/* <ProductPlacement ref={productPlacement} /> */}
      <Applications ref={applications} />
      <ProductFeatures ref={productFeatures} />
      <Strategy ref={strategy} />
      <Analytics ref={trajectory} />
      <Team ref={team} />
      <Ask ref={ask} />
      <Close ref={close} />
    </>
  );
}

export default App;
