import { forwardRef } from 'react';
import GraphAnimation from '../animations/GraphAnimation';
import Slide from '../global/Slide';

import styles from './Analytics.module.css';

const content = [
  {
    title: 'Retention',
    graph: 'user-registrations',
    xAxis: ['Aug 20', 'Oct 20', 'Jan 21', 'Apr 21', 'Aug 21'],
    yAxis: ['0', '20k', '40k', '60k', '80k'],
    caption: 'Sustained registration growth',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: '16k active users in March',
    graph: 'monthly-teams',
    xAxis: ['Aug 20', 'Oct 20', 'Jan 21', 'Apr 21', 'Aug 21'],
    yAxis: ['0', '25', '50', '75', '100'],
    caption: 'Increasing team accounts',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
  {
    title: '42k sign ups',
    graph: 'activated-retention',
    xAxis: ['0', '1', '2', '3'],
    yAxis: ['0%', '25%', '50%', '75%', '100%'],
    caption: '70% activated user retention',
    captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
  },
]

const Analytics = forwardRef((_, ref) => {
  return (
    <Slide ref={ref} className={styles.analytics}>
      <h3>Analytics</h3>
      <h2>Organic growth, strategic retention</h2>
      <p>User registrations continue to grow organically. Retention remains strongest amongst those engaging in our advanced features.</p>
      <div className={styles.threeUp}>
        {content.map(item => (
          <div key={item.graph}>
            <GraphAnimation
              graph={item.graph}
              xAxis={item.xAxis}
              yAxis={item.yAxis}
            />
            <h3>{item.caption}</h3>
            {/* <h3>{item.title}</h3> */}
            {/* <p>{item.caption}</p> */}
          </div>
        ))}
      </div>
    </Slide>
  )
})

export default Analytics;