import { forwardRef } from 'react';
import Rive, { Fit, Layout } from 'rive-react';
import Slide from '../global/Slide';

import styles from './Strategy.module.css';

const content = [
  {
    heading: 'Actively bring users in',
    animation: 'rollercoaster.riv',
    items: [
      {
        title: 'Growth and acquisition',
        caption: 'We are confident we can continue to build a ground-breaking tool, but it means nothing if we cannot get people in. So far all our growth has been through word of mouth. We plan to implement backlinks and comparison pages, sponsor content where potential users are already watching (e.g. Animation/interaction design/dev channels on YouTube), and integrate with other design/dev tools to make it easy to discover Rive (e.g. WebFlow and Figma).',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      }
    ]
  },
  {
    heading: 'Creator Relations',
    animation: 'strike_force.riv',
    items: [
      {
        title: 'Nurture loyal champions',
        caption: 'We have started hiring creative and technical evangelists as part of our Creator Relations team. They demonstrate the benefits of the product for each segment while nurturing an active community by teaching, attending/hosting events, maintaining documentation, writing case studies, building examples, and creating tutorials.',
        captionLong: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in arcu quis tellus rutrum posuere. Aenean eget eros sed dui gravida congue ut ac ligula. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla elementum, arcu sit amet tristique molestie, nunc neque bibendum nunc, vitae ultrices nunc felis quis justo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In eleifend odio in tortor aliquet, eu hendrerit leo eleifend. Quisque venenatis nibh quis nulla viverra, non varius enim ullamcorper. Pellentesque dapibus vulputate mauris. Nullam suscipit eget mauris quis pretium. Nunc nulla augue, rutrum a augue a, fermentum porta diam.'
      }
    ]
  }
]

const MarketStrategy = forwardRef((_, ref) => {
  return (
    <Slide ref={ref} className={styles.strategy}>
      <h2>We're ready to go to market</h2>
      <h4>We've built a platform that addresses the core problem for our initial segment, now we need to go-to-market.</h4>
      <div className={styles.threeUp}>
        {content.map(section => (
          <div key={section.heading}>
            <div className={styles.ratio}>
              <Rive className={styles.featureAnimation} src={section.animation} layout={new Layout({ fit: Fit.Cover })} />
            </div>
            <h3>{section.heading}</h3>
            <div className={styles.divider}/>
            {section.items.map(item => (
              // <Expandable 
              //   key={item.title} 
              //   className={styles.calloutVert}
              //   modalHeader={item.title}
              //   modalCopy={item.captionLong}
              // >
              //   <h4>{item.title}</h4>
              //   <p>{item.caption}</p>
              // </Expandable>
              <div key={item.title} className={styles.calloutVert}>
                <h4>{item.title}</h4>
                <p>{item.caption}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </Slide>
  )
})

export default MarketStrategy;