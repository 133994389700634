import { forwardRef } from 'react';
import Slide from '../global/Slide';
import Rive, { Fit, Layout } from 'rive-react';

import styles from './Ask.module.css';

const content = [
  {
    title: 'Phase 1 – Product development',
    caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut molestie fringilla blandit. Duis mauris urna.',
  },
  {
    title: 'Phase 2 – Go to market',
    caption: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut molestie fringilla blandit. Duis mauris urna.',
  },
]

const Ask = forwardRef((_, ref) => {
  return (
    <Slide ref={ref} className={styles.ask} hideDivider>
      <div style={{ width: '100%' }}>
        <div className={styles.ratio}>
          <Rive className={styles.visual} src="/iron-giant.riv" layout={new Layout({ fit: Fit.Cover })} />
        </div>
      </div>
      <div className={styles.copyContainer}>
        <div className={styles.copy}>
          <h3>Our ask</h3>
          <h2>We're seeking $10 million to go-to-market and continue product development.</h2>
          
        </div>
      </div>
    </Slide>
  );
})

export default Ask;